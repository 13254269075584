import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core"

import { WifiStatus } from "src/components/Devices/NetworkStatus/WifiStatus"
import { SearchFilter } from "src/components/Filter/SearchFilter"
import { BatteryAndChargeStatusIcon } from "src/components/Homes/DeviceDetails/BatteryAndChargeStatusIcon"
import { OnlineStatus } from "src/components/Homes/DeviceDetails/OnlineStatus"
import { ParadiseDeviceHardwareCombobox } from "src/components/Paradise/ParadiseDeviceHardwareCombobox"
import { ParadiseDevicesFilters } from "src/components/Paradise/ParadiseDevicesFilter"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  Ellipsis,
  IdWrapper,
  PillsWrapper,
  StatusWrapper,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import {
  TParadiseDevicesFilters,
  TSortedParadiseDevicesFilters,
} from "src/components/Paradise/useParadiseDevicesFilter"
import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import { TParadiseDevice } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { TSetSearchParamsProp } from "src/router/useSearchParams"
import { mColors } from "src/ui/colors"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { ColumnPopoverWrapper } from "src/ui/GridTable/useTableColumns/tableColumnStyles"
import {
  TableColumn,
  TTableSort,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import FilterIcon from "src/ui/icons/calibrating.svg"
import InfoIcon from "src/ui/icons/info.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { formatUtcDate } from "src/utils/l10n"
import { Maybe } from "src/utils/tsUtil"

type TParadiseDevicesTableColumnValues =
  | "device_id"
  | "device_mac"
  | "description"
  | "owner"
  | "tags"
  | "last_updated_at"
  | "status"
  | "battery"
  | "signal"
  | "firmware"
  | "hardware"

export function ParadiseDevicesTable({
  devices,
  offset,
  limit,
  setOffset,
  filter,
  setFilter,
  sortedFilters,
  sort,
  setSort,
  totalCount,
  error,
  isLoading,
  disableColumnHiding,
  tableStorageKey,
  hiddenColumns,
}: {
  devices: TParadiseDevice[]
  offset: number
  limit: number
  setOffset: (offset: number) => void
  filter: TParadiseDevicesFilters
  sortedFilters: TSortedParadiseDevicesFilters
  setFilter: TSetSearchParamsProp<TParadiseDevicesFilters>
  sort?: Maybe<TTableSort>
  setSort?: (sort: TTableSort) => void
  totalCount?: number
  isLoading: boolean
  error?: {
    hasError: boolean
    title: React.ReactNode
    description?: React.ReactNode
  }
  disableColumnHiding?: boolean
  tableStorageKey?: string
  hiddenColumns?: TParadiseDevicesTableColumnValues[]
}) {
  const { navigate } = useRouter()

  const tableColumns: TableColumn<
    TParadiseDevice,
    TParadiseDevicesTableColumnValues
  >[] = [
    {
      label: "Device id",
      value: "device_id",
      disabled: true,
      enableSort: true,
      columnWidth: "min-content",
      disableClickPropagation: true,
      renderCell: (data) => (
        <IdWrapper>
          <InternalLink
            to={Routes.ParadiseDevice.location(data.device_id).pathname}
          >
            {data.device_id}
          </InternalLink>
          {!data.active && (
            <MTooltip title="Inactive">
              <InfoIcon width={12} fill={mColors.systemErrorLight} />
            </MTooltip>
          )}
        </IdWrapper>
      ),
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.devices_id ?? undefined}
            placeholder="Search by id"
            onChange={(v) => setFilter("devices_id", v)}
          />
          <MText variant="bodyS">Make sure to type in the exact ID</MText>
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Device mac",
      value: "device_mac",
      disabled: true,
      enableSort: true,
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.device_mac}</div>,
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.devices_mac ?? undefined}
            placeholder="Search by MAC address"
            onChange={(v) => setFilter("devices_mac", v)}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Description",
      value: "description",
      columnWidth: 300,
      disabled: true,
      enableSort: true,
      renderCell: (data) => <Ellipsis>{data.description}</Ellipsis>,
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.devices_description ?? undefined}
            placeholder="Search by description"
            onChange={(v) => setFilter("devices_description", v)}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Owner",
      value: "owner",
      enableSort: true,
      columnWidth: "min-content",
      disableClickPropagation: true,
      renderCell: (data) => (
        <InternalLink to={Routes.ParadiseUser.location(data.owner).pathname}>
          {data.owner}
        </InternalLink>
      ),
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.devices_owner ?? undefined}
            placeholder="Search by owner ID"
            onChange={(v) => setFilter("devices_owner", v)}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Tags",
      value: "tags",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>
          <PillsWrapper $noWrap>
            {data.tags?.map((tag, i) => (
              <MBadge size="x-small" key={tag + i}>
                {tag}
              </MBadge>
            ))}
          </PillsWrapper>
        </div>
      ),
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.devices_tag ?? undefined}
            placeholder="Search by tag"
            onChange={(v) => setFilter("devices_tag", v)}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Last updated at",
      enableSort: true,
      value: "last_updated_at",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>
          {data.last_heard_from_at
            ? formatUtcDate({ date: data.last_heard_from_at })
            : "-"}
        </div>
      ),
    },
    {
      label: "Status",
      enableSort: true,
      value: "status",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>
          <OnlineStatus online={!data.offline} />
        </div>
      ),
      popoverContent: (
        <ColumnPopoverWrapper>
          <RadioGroup
            onChange={(e) =>
              setFilter("devices_offline", e.target.value === "true")
            }
            value={String(filter.devices_offline)}
          >
            <FormControlLabel
              value="false"
              label="Online devices only"
              control={<Radio />}
            />
            <FormControlLabel
              value="true"
              label="Offline devices only"
              control={<Radio />}
            />
          </RadioGroup>
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Battery",
      enableSort: true,
      value: "battery",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>
          <StatusWrapper>
            <BatteryAndChargeStatusIcon
              percent={data.battery?.percent}
              offline={data.offline}
              chargeStatus={data.charge_status}
              size={20}
            />
            {data.battery?.voltage && (
              <MText variant="nano">{data.battery?.voltage.toFixed(2)}V</MText>
            )}
          </StatusWrapper>
        </div>
      ),
    },
    {
      label: "Signal",
      value: "signal",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>
          <StatusWrapper>
            <WifiStatus
              quality={data.wlan?.signal_quality}
              offline={data.offline}
              size={15}
            />
            {data.wlan?.rssi && <MText variant="nano">{data.wlan?.rssi}</MText>}
          </StatusWrapper>
        </div>
      ),
    },
    {
      label: "Firmware",
      enableSort: true,
      value: "firmware",
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.firmware.installed}</div>,
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.devices_firmware ?? undefined}
            placeholder="Search by firmware"
            onChange={(v) => setFilter("devices_firmware", v)}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Hardware",
      enableSort: true,
      value: "hardware",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>{getDeviceHardwareType(data.hardware_version).type}</div>
      ),
      popoverContent: (
        <ColumnPopoverWrapper>
          <ParadiseDeviceHardwareCombobox
            label="Hardware version"
            selectedHardwareVersion={filter.devices_hardwareVersion}
            onChange={(v) => setFilter("devices_hardwareVersion", v)}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
  ]

  const {
    headerElements,
    rows,
    interactiveColumns,
    updateColumnVisibility,
    interactiveVisibleColumns,
    templateColumns,
  } = useTableColumns<TParadiseDevice>({
    columns: tableColumns.map((c) => ({
      ...c,
      hidden: hiddenColumns?.includes(c.value),
    })),
    data: devices,
    sort: sort,
    onSortChange: setSort,
    options: { localStorageKey: tableStorageKey, disableColumnHiding },
  })

  return (
    <div>
      <TopWrapper>
        <ParadiseDevicesFilters
          filter={filter}
          setFilter={setFilter}
          sortedFilters={sortedFilters}
        />

        {!disableColumnHiding && (
          <DropdownMultiSelect
            label="Columns"
            selectedValues={interactiveVisibleColumns.map((c) => c.value)}
            options={interactiveColumns}
            onChange={({ checked, option }) => {
              updateColumnVisibility(option.value, !checked)
            }}
          />
        )}
      </TopWrapper>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          // @ts-expect-error: noUncheckedIndexedAccess
          navigate(Routes.ParadiseDevice.location(devices[index].device_id))
        }}
        error={error}
        isLoading={isLoading}
      />
      <ParadisePager
        totalCount={totalCount}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />
    </div>
  )
}
