import styled from "styled-components"

import { FormControlLabel, Radio, RadioGroup, Switch } from "@material-ui/core"

import { ParadiseAppliedFilters } from "src/components/Paradise/ParadiseAppliedFilters"
import {
  TParadiseDevicesFilters,
  TSortedParadiseDevicesFilters,
} from "src/components/Paradise/useParadiseDevicesFilter"
import { TSetSearchParamsProp } from "src/router/useSearchParams"
import { MButton } from "src/ui/Button/MButton"
import { FilterChip, FilterChipProps } from "src/ui/Filter/FilterChip"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const deviceFilterLabel: Record<string, string> = {
  devices_description: "Description",
  devices_hardwareVersion: "Hardware version",
  devices_id: "Device id",
  devices_mac: "MAC",
  devices_firmware: "Firmware",
  devices_tag: "Tag",
  devices_owner: "Owner",
} as const

export function ParadiseDevicesFilters({
  filter,
  setFilter,
  sortedFilters,
}: {
  filter: TParadiseDevicesFilters
  setFilter: TSetSearchParamsProp<TParadiseDevicesFilters>
  sortedFilters: TSortedParadiseDevicesFilters
}) {
  const customFilterChips: FilterChipProps[] = [
    {
      label: "Include inactive",
      value: filter.devices_include_inactive ? "True" : "False",
      active: !!filter.devices_include_inactive,
      onRemove: () => setFilter("devices_include_inactive", null),
      removeLabel: "Reset",
      children: (
        <FilterContent>
          <MText variant="subtitleS" marginBottom={spacing.S}>
            Include inactive devices
          </MText>
          <Switch
            checked={!!filter.devices_include_inactive}
            onChange={(_, checked) =>
              setFilter("devices_include_inactive", checked)
            }
          />
        </FilterContent>
      ),
    },
    {
      label: "Status",
      value: filter.devices_offline
        ? "Offline devices only"
        : "Online devices only",
      active: filter.devices_offline !== null,
      onRemove: () => setFilter("devices_offline", null),
      removeLabel: "Reset",
      children: (
        <FilterContent>
          <RadioGroup
            onChange={(e) =>
              setFilter("devices_offline", e.target.value === "true")
            }
            value={String(filter.devices_offline)}
          >
            <FormControlLabel
              value="false"
              label="Online devices only"
              control={<Radio />}
            />
            <FormControlLabel
              value="true"
              label="Offline devices only"
              control={<Radio />}
            />
          </RadioGroup>
        </FilterContent>
      ),
    },
  ]

  const appliedFilters = sortedFilters.map((f) => {
    const label = deviceFilterLabel[f.key]

    return {
      label: label ?? "",
      value: String(f.value),
      onRemove: () => setFilter(f.key, null),
    }
  })

  const hasAppliedFilters =
    sortedFilters.length > 0 || customFilterChips.some((f) => f.active)

  return (
    <FilterWrapper>
      {customFilterChips.map((filterChip) => (
        <FilterChip key={filterChip.label} {...filterChip} />
      ))}

      <ParadiseAppliedFilters filters={appliedFilters} />

      {hasAppliedFilters && (
        <MButton
          onClick={() => {
            customFilterChips.forEach((filterChip) => filterChip.onRemove?.())
            appliedFilters.forEach((filter) => filter.onRemove())
          }}
          variant="minimal"
          size="small"
        >
          Clear filters
        </MButton>
      )}
    </FilterWrapper>
  )
}

const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.M};
  margin-top: ${spacing.M};
`

const FilterContent = styled.div`
  padding: ${spacing.XS};
`
