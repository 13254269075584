import { useState } from "react"

import { useDebounceValue } from "usehooks-ts"

import { useFetchParadiseHardwareVersions } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { Combobox } from "src/ui/Combobox/Combobox"

export function ParadiseDeviceHardwareCombobox({
  label,
  selectedHardwareVersion,
  onChange,
}: {
  label: string
  selectedHardwareVersion: number | null
  onChange: (hardwareVersions: number | null) => void
}) {
  const stringifiedHardwareVersion = selectedHardwareVersion?.toString() || ""

  const [searchedVersionNumber, setSearchedVersionNumber] = useState(
    stringifiedHardwareVersion
  )
  const [debouncedVersionNumber, setDebouncedVersionNumber] = useDebounceValue(
    stringifiedHardwareVersion,
    500
  )

  const fetchHardwareVersions = useFetchParadiseHardwareVersions({
    options: {
      select: (data) => {
        return data.filter((v) => {
          const lowerSearch = debouncedVersionNumber.toString().toLowerCase()

          return (
            v.name.toLowerCase().includes(lowerSearch) ||
            v.version_number.toString().toLowerCase().includes(lowerSearch)
          )
        })
      },
    },
  })
  const hardwareVersions = fetchHardwareVersions.data

  return (
    <Combobox
      label={label}
      options={
        hardwareVersions?.map((version) => ({
          label: `${version.name} (${version.version_number})`,
          value: version.version_number.toString(),
          selectedLabelText: version.version_number.toString(),
        })) ?? []
      }
      selectedValue={stringifiedHardwareVersion}
      input={searchedVersionNumber}
      onInput={(value) => {
        if (value !== "") {
          const convertedValue = parseInt(value)
          onChange(!isNaN(convertedValue) ? convertedValue : null)
        } else {
          onChange(null)
        }

        setSearchedVersionNumber(value)
        setDebouncedVersionNumber(value)
      }}
      // The options in the Combobox are mainly used for suggestions.
      // The user can also type in a random value that will be used as the filter value.
      // All `change` handling are handled in `onInput`.
      onChange={() => {}}
    />
  )
}
